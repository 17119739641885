import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	Box,
	Table,
	TableContainer,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	TablePagination,
	TableSortLabel,
	Switch,
	Checkbox,
	Paper,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ViewButtonSingle } from "../../Shared/ViewButton/ViewButton";
import * as tableConstants from "../../Shared/Config/TableConfig";
import * as inventoryTableConfig from "./InventoryTableConfig";
import "./InventoryTable.scss";
import {
	stableSort,
	getComparator
} from "../../../utils/InventoryTable";
import ColumnVisibility from "../../Shared/ColumnVisibility/ColumnVisibility";
import { camelCase } from "../../../utils/text";
import { StatusBox } from "../../Shared/StatusBox/StatusBox";
import { getPropertyById } from "../../../utils/object";
import { ProductImage } from "../../Shared/ProductImage/ProductImage";

export default function EnhancedTable({
	inventory, warehouses, isVisibleBulkAction, isVisibleIsHiddenForSeller,
	selectedProducts, onChangeSelectedProducts, productStatuses
}) {

	const pageOptions = tableConstants.PAGINATION_OPTIONS;
	const sortOrder = tableConstants.TABLE_SORT_ORDER;
	const tableHeight = tableConstants.TABLE_HEIGHT;
	const orderedBy = inventoryTableConfig.TABLE_COLOUMN_ORDER_BY;
	const tableSpan = inventoryTableConfig.TABLE_SPAN;
	const tableName = inventoryTableConfig.TABLE_NAME;
	const tableID = inventoryTableConfig.TABLE_ID;
	const pageClickURL = inventoryTableConfig.CLICK_PAGE_URL;
	const pageClickTitle = inventoryTableConfig.CLICK_PAGE_TITLE;
	const [order, setOrder] = useState(sortOrder);
	const [orderBy, setOrderBy] = useState(orderedBy);
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(tableConstants.PAGINATION_OPTIONS[0]);
	const [productImage, setProductImage] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);

	const headCells = [
		{
			id: "is_hidden_for_seller",
			alignRight: false,
			disablePadding: true,
			label: "Hidden",
			visible: isVisibleIsHiddenForSeller && !isVisibleBulkAction,
		},
		{
			id: "image",
			alignRight: false,
			disablePadding: true,
			label: "Image",
			visible: true,
		},
		{
			id: "sku",
			alignRight: false,
			disablePadding: true,
			label: "SKU",
			visible: true,
		},
		{
			id: "name",
			alignRight: false,
			disablePadding: true,
			label: "Name",
			visible: true,
		},
		{
			id: "is_bundle",
			alignRight: false,
			disablePadding: true,
			label: "Type",
			visible: true,
		},
		{
			id: "warehouse_id",
			alignRight: false,
			disablePadding: true,
			label: "Warehouse",
			visible: true,
		},
		{
			id: "stock_available",
			alignRight: false,
			disablePadding: true,
			label: "Stock Available",
			visible: true,
		},
		{
			id: "allocated",
			alignRight: false,
			disablePadding: true,
			label: "Allocated",
			visible: true,
		},
		{
			id: "status_id",
			alignRight: false,
			disablePadding: true,
			label: "Status",
			visible: true,
		},
		{
			id: "in_quarantine",
			alignRight: false,
			disablePadding: true,
			label: "Quarantined",
			visible: true,
		},
		{
			id: "button",
			alignRight: true,
			disablePadding: true,
			label: "button",
			visible: true,
		},
	];

	const localStorageName = "inventory_column_visibility";

	const getInitialColumnVisibility = () => {
		const savedState = localStorage.getItem(localStorageName);
		if (savedState) {
			return JSON.parse(savedState);
		}

		const initialVisibility = {};
		headCells.forEach((cell) => {
			initialVisibility[camelCase(cell.label)] = true;
		});
		return initialVisibility;
		
	}; 

	const [columnsVisibility, setColumnsVisibility] = useState(getInitialColumnVisibility());
	const handleVisibilityChange = (newColumnsVisibility) => {
		setColumnsVisibility(newColumnsVisibility);
		setIsModalVisible(true);
		localStorage.setItem(localStorageName, JSON.stringify(newColumnsVisibility));
	};

	function EnhancedTableHead(props) {
		const { order, orderBy, onRequestSort } =
			props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<TableHead>
				<TableRow>
					{isVisibleBulkAction && <TableCell>
						<Switch
							checked={areAllOrdersSelected()}
							onChange={(e) => onChangeToggleAllCheckBoxes(e)}
							inputProps={{ "aria-label": "controlled" }}
						/>
					</TableCell>
					}
					{headCells.map((headCell) => (
						headCell.visible && 
						<TableCell
							key={headCell.id}
							align={headCell.alignRight ? "right" : "left"}
							padding={headCell.disablePadding ? "normal" : "none"}
							sortDirection={orderBy === headCell.id ? order : false}
							style={{ display: columnsVisibility[camelCase(headCell.label)] ? "" : "none" }}
						>
							{headCell.id === "button" ? 
								<ColumnVisibility
									columnsDisabled={["sku", "hidden"]}
									columnsVisibility={columnsVisibility}
									onVisibilityChange={handleVisibilityChange}
									isModalVisible={isModalVisible}
								/>
								:
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : "asc"}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component="span" sx={visuallyHidden}>
											{order === "desc" ? "sorted descending" : "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	EnhancedTableHead.propTypes = {
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	const handleRequestSort = (event, property) => {
		setIsModalVisible(false);
		const isAsc = orderBy === property && order === sortOrder;
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	let rows = inventory.sort((a, b) => a.sku > b.sku ? -1 : 1);

	const getWarehouseName = (id) => {

		if (id === "" || id === undefined) return "";
		const warehouse = warehouses.find(obj => obj.id === Number(id));

		if (warehouse === undefined) return "";
		return warehouse.name;

	};

	const handleSelectAllClick = (event) => {
		setIsModalVisible(false);
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClickImage = (image_url, sku) => {
		setIsModalVisible(false);
		setProductImage({ url: image_url, sku });
	};

	const handleCloseImage = () => {
		setProductImage(null);
	};
	
	const onChangeCheckBox = (e) => {
		setIsModalVisible(false);

		if (selectedProducts === undefined) {
			return;
		}

		const product = e.target.name;
		const isProductChecked = e.target.checked;
		const isProductInArray = selectedProducts.includes(product);

		if (isProductChecked === true && isProductInArray === false) {
			onChangeSelectedProducts([...selectedProducts, product]);
		}

		if (isProductChecked === false && isProductInArray === true) {
			const uniqueCheckedProducts = selectedProducts.filter(p => p !== product);

			onChangeSelectedProducts(uniqueCheckedProducts);
		}

	};

	const onChangeToggleAllCheckBoxes = (e) => {
		setIsModalVisible(false);
		const isChecked = e.target.checked;
		const selectedProductSkus = isChecked ? inventory.map(p => p.sku) : [];
		onChangeSelectedProducts(selectedProductSkus);
	};

	const areAllOrdersSelected = () => selectedProducts.length === inventory.length;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const pageRows = () => {

		if (!inventory.length) return;

		const getPageRows = pageOptions.map((item) => {
			return item <= inventory.length ? item : 0;
		}
		);

		const cleanRows = getPageRows.filter(function (row) {
			return row !== 0;
		});

		return cleanRows;

	};

	return (
		<Box id="mui-table">
			<TableContainer sx={{ maxHeight: tableHeight, overflow: "auto" }} component={Paper}>
				<Table
					aria-labelledby={tableName}
					id={tableID}
					stickyHeader
				>
					<EnhancedTableHead
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={rows.length}
					/>
					<TableBody>
						{/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
						{stableSort(rows, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								return (
									<TableRow key={index}>
										{isVisibleIsHiddenForSeller &&
											<TableCell>
												<Checkbox
													checked={selectedProducts.includes(row.sku) ? true : false}
													onChange={(e) => onChangeCheckBox(e)}
													inputProps={{ "aria-label": "controlled" }}
													name={row.sku.toString()}
													disabled={!isVisibleBulkAction}
												/>
											</TableCell>
										}
										<TableCell style={{ display: columnsVisibility.image ? "" : "none" }}>
											{(row.image_url !== "") &&
												<ProductImage 
													buttonType="thumbnail"
													onClick={() => handleClickImage(row.image_url, row.sku)}
													isVisible={productImage?.sku === row.sku}												
													onClose={handleCloseImage}
													imageURL={row.image_url}
												/>
											}
										</TableCell>
										<TableCell>{row.sku}</TableCell>
										<TableCell style={{ display: columnsVisibility.name ? "" : "none" }}>
											{row.name}
										</TableCell>
										<TableCell style={{ display: columnsVisibility.type ? "" : "none" }}>
											{row.is_bundle ? "Bundle" : "Product"}
										</TableCell>
										<TableCell style={{ display: columnsVisibility.warehouse ? "" : "none" }}>
											{getWarehouseName(row.warehouse_id)}
										</TableCell>
										<TableCell style={{ display: columnsVisibility.stockAvailable ? "" : "none" }}>
											{row.is_bundle ? "-" : row.stock_available}
										</TableCell>
										<TableCell style={{ display: columnsVisibility.allocated? "" : "none" }}>
											{row.is_bundle ? "-" : row.allocated}
										</TableCell>
										<TableCell style={{ display: columnsVisibility.status ? "" : "none" }}>
											<StatusBox 
												status={getPropertyById(productStatuses, row.status_id) || "undefined"}
												statusType={"inventory"}
											/>
										</TableCell>
										<TableCell style={{ display: columnsVisibility.quarantined ? "" : "none" }}>
											{row.is_bundle ? "-" : row.in_quarantine}
										</TableCell>
										<TableCell title="View details" align="right">
											<ViewButtonSingle
												buttonTitle={pageClickTitle}
												buttonLink={pageClickURL}
												buttonProps={[
													{
														"id": row.product_id,
														"is_hidden" : row.is_hidden_for_seller,
													}
												]}
											/>
										</TableCell>
									</TableRow>
								);
							})}
						{emptyRows > 0 && (
							<TableRow>
								<TableCell colSpan={tableSpan} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={pageRows()}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	);
}
