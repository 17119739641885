import React, {
	useState,
	useCallback,
} from "react";
import {
	Link,
	useNavigate,
	useParams,
	useLocation
} from "react-router-dom";
import SellerDependentView from "../Shared/SellerDependentView";
import UseFetchData from "../Shared/FetchData/FetchData";
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Button,
	Grid,
} from "@mui/material";
import { LoadingStatusTable } from "../Shared/LoadingStatus/LoadingStatus";
import "./ProductDetail.scss";
import { DataContext } from "../../App";
import { getDisplayTime } from "../../utils/date";
import {
	getProductBarcodeTypeName,
	getProductBarcodeTypes
} from "../../utils/barcodeTypes";
import { setDocumentTitle } from "../../utils/page";
import { getPropertyById } from "../../utils/object";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { roundToPrecision } from "../../utils/number";
import { ProductImage } from "../Shared/ProductImage/ProductImage";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";
import { getUserPermissions } from "../../utils/permissions";
import { LightTooltip } from "../Shared/LightTooltip/LightTooltip";

export default function ProductDetail({ seller, userdata }) {

	const product = useParams()?.id;
	const location = useLocation();
	const isHidden = location?.state?.isHidden;
	const context = React.useContext(DataContext);
	const navigate = useNavigate();

	const { status, results, isLoading } = UseFetchData(useCallback(() => context.dataProvider.getProduct(product), [context.dataProvider, product]));
	const stockLevels = UseFetchData(useCallback(() => context.dataProvider.getProductStock(seller, product), [context.dataProvider, seller, product]));
	const productStatuses = UseFetchData(useCallback(() => context.dataProvider.getProductStatuses(), [context.dataProvider]));
	const [showProductImage, setShowProductImage] = useState(false);

	const permissionMap = {
		"view_inventory_detail": "inventory-detail",
		"create_product": "create_product"
	};
	let permissions = getUserPermissions(userdata, permissionMap);

	const page_data = {
		main: {
			sku: results.sku,
			name: results.name,
			description: results.description,
			barcode_type: results.barcode_type,
			barcode_number: results.barcode_number,
			status_id: results.status_id,
			alt_codes: results.alt_codes,
		},
		dimensions: {
			weight: results.weight_kg,
			height: results.height_cm,
			length: results.length_cm,
			depth: results.depth_cm,
		},
		extraDetails: {
			retail_price: results.retail_price,
			cost_price: results.cost_price,
			commodity_code: results.commodity_code,
			country_of_manufacture: results.country_of_manufacture,
			image_url: results.image_url,
			customs_description: results.customs_description,
		},
		stock: {
			resultsData: stockLevels.results,
			status: stockLevels.status,
			isLoading: stockLevels.isLoading,
		},
		productStatuses: {
			resultsData: productStatuses.results,
			isLoading: productStatuses.isLoading,
		},
		barcodeTypes: getProductBarcodeTypes(),
	};

	const BackButton = () => (
		<Button variant="contained" component={Link} to="/inventory">
			Go Back
		</Button>
	);

	const onClickShowProductImage = () => {
		setShowProductImage(true);
	};

	const onCloseHideProductImage = () => {
		setShowProductImage(false);
	};

	const pageName = `Product Details: ${page_data.main.sku}`;
	setDocumentTitle(pageName);

	const TitleHeading = () => (
		<Box>
			<Grid container spacing={1} mt={1}>
				<Grid item xs={12} container spacing={2} mb={2}>
					<Grid item xs="auto">
						<Typography component="h1" variant="h5">
							{pageName}
							{isHidden && 
								<LightTooltip
									title="This SKU is currently set to hidden. To change go to the Inventory main page and select bulk actions > hide skus."
									arrow
								>
									<Box 
										component="span" 
										sx={{ position: "relative", bottom: "4px" }}
									>
										<StatusBox 
											sx={{ ml: 1 }} 
											status={"hidden"} 
											statusType={"inventory"}
										/>
									</Box>
								</LightTooltip>
							}
						</Typography>
					</Grid>
					<Grid item xs />

					{permissions.has("create_product") &&
						<Grid item xs="auto">
							<Box>
								<Button
									variant="outlined"
									onClick={() => navigate("/product-update", { state: { product: results } })}
									color="primary"
								>
									Edit Product
								</Button>
							</Box>
						</Grid>
					}

					{(page_data.extraDetails.image_url &&
						page_data.extraDetails.image_url !== "<nil>") && 
						<Grid item xs="auto">
							<Box>
								<ProductImage 
									buttonType={"button"}
									onClick={onClickShowProductImage}
									isVisible={showProductImage}
									onClose={onCloseHideProductImage}
									imageURL={page_data.extraDetails.image_url}
								/>
							</Box>
						</Grid>
					}

				</Grid>
			</Grid>
		</Box>
	);

	const Summary = () => (
		<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
			<Typography component="h1" variant="h5" mb={1}>
				Summary
			</Typography>
			
			<Grid container spacing={1}>
				
				<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Product</Box>
						<Box component="span">{`${page_data.main.name} (${page_data.main.sku})`}</Box>
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={6} xl={6}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold" }}>Product Status:</Box>
						<Box component="span">
							<StatusBox 
								sx={{ ml: 1 }} 
								status={getPropertyById(page_data.productStatuses.resultsData, page_data.main.status_id)} 
								statusType={"inventory"} 
							/>
						</Box>
					</Typography>
				</Grid>

			</Grid>
		</Paper>
	);
	
	const ProductsTableData = () => {
		if (page_data.stock.status === true) {
			return (
				page_data.stock.resultsData.map((product, index) =>
					<TableRow key={index}>
						<TableCell>{`${product.warehouse_name} (${product.warehouse_code})`}</TableCell>
						<TableCell>{product.stock_available}</TableCell>
						<TableCell>{product.allocated}</TableCell>
						<TableCell>{product.in_quarantine}</TableCell>
						<TableCell>{getDisplayTime(product.last_updated)}</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="5" align="center">No details found</TableCell>
				</TableRow>
			);
		}
	};

	const ProductsTable = () => (
		<>
			<Typography component="h1" variant="h5" mb={1}>
				Stock Levels
			</Typography>
			<TableContainer component={Paper} sx={{ mb: 1 }}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Warehouse</TableCell>
							<TableCell>Stock Available</TableCell>
							<TableCell>Allocated</TableCell>
							<TableCell>Quarantined</TableCell>
							<TableCell>Last Updated</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{page_data.stock.isLoading ?
							<LoadingStatusTable message="Loading ..." colSpan={5} />
							:
							<ProductsTableData />
						}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);

	const StockLevels = () => (
		<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
			<ProductsTable />
		</Paper>
	);

	const Identifiers = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Typography component="h1" variant="h5" mb={1}>
				Identifiers
			</Typography>

			<Grid item xs="auto" mb={1}>
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>SKU Code:</Box>
					<Box component="span">{page_data.main.sku}</Box>
				</Typography>
			</Grid>

			<Grid item xs="auto" mb={1}>
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Product Name:</Box>
					<Box component="span">{page_data.main.name}</Box>
				</Typography>
			</Grid>

			<Grid item xs="auto" mb={1}>
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Barcode:</Box>
					<Box component="span">{page_data.main.barcode_number}</Box>
				</Typography>
			</Grid>

			<Grid item xs="auto" mb={1}>
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Barcode Type:</Box>
					<Box component="span">{getProductBarcodeTypeName(page_data.main.barcode_type)}</Box>
				</Typography>
			</Grid>

			<Grid item xs="auto" mb={1}>
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Product Description:</Box>
					<Box component="span">{page_data.main.description}</Box>
				</Typography>
			</Grid>

			{(page_data.main.alt_codes) &&
				<Grid item xs="auto" mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>{page_data.main.alt_codes.length > 1 ? "Alternative Codes:" : "Alternative Code:"}</Box>
						<Box component="span">{page_data.main.alt_codes.join(",")}</Box>
					</Typography>
				</Grid>
			}

		</Paper>
	);

	const Dimensions = () => (
		<Paper sx={{ p: 2, width: "100%", mb: 2, }} elevation={2}>
			<Typography component="h1" variant="h5" mb={1}>
				Dimensions
			</Typography>

			<Grid container>
				<Grid item xs={12} sm={6} mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Weight:</Box>
						<Box component="span">{roundToPrecision(page_data.dimensions.weight)}KG</Box>
					</Typography>
				</Grid>

				<Grid item xs={12} sm={6} mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Height:</Box>
						<Box component="span">{page_data.dimensions.height}CM</Box>
					</Typography>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12} sm={6} mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Length:</Box>
						<Box component="span">{page_data.dimensions.length}CM</Box>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Depth:</Box>
						<Box component="span">{page_data.dimensions.depth}CM</Box>
					</Typography>
				</Grid>
			</Grid>

		</Paper>
	);

	const CustomsInformation = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Typography component="h1" variant="h5" mb={1}>
				Customs Information
			</Typography>
		
			<Grid container>
				<Grid item xs={12} sm={6} mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Retail Price:</Box>
						<Box component="span">£{roundToPrecision(page_data.extraDetails.retail_price)}</Box>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6} mb={1}>
					<Typography component="div">
						<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Cost Price:</Box>
						<Box component="span">£{roundToPrecision(page_data.extraDetails.cost_price)}</Box>
					</Typography>
				</Grid>
			</Grid>

			<Grid item xs="auto" mb={1}>
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Commodity Code:</Box>
					<Box component="span">{page_data.extraDetails.commodity_code}</Box>
				</Typography>
			</Grid>

			<Grid item xs="auto" mb={1}>
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Country of Manufacture:</Box>
					<Box component="span">{page_data.extraDetails.country_of_manufacture}</Box>
				</Typography>
			</Grid>

			<Grid item xs="auto">
				<Typography component="div">
					<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Customs Description:</Box>
					<Box component="span">{page_data.extraDetails.customs_description}</Box>
				</Typography>
			</Grid>

		</Paper>
	);
	
	return (
		<Box id="product-detail">
			<LoadableContent isLoading={isLoading || page_data.main.name == undefined}>
				<SellerDependentView seller={seller}>
					<Box>
						{status === true ?
							<>
								<BreadCrumb
									breadcrumbs={[
										{ label: "Inventory", url: "/inventory" },
										{ label: page_data?.main?.sku}
									]}
								/>
								<TitleHeading/>
								<Summary/>
								<StockLevels/>

								<Grid container spacing={2} sx={{ display: "flex" }}>
									<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
										<Identifiers />
									</Grid>
									<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
										<Box sx={{ flex: 1 }}>
											<Dimensions/>
											<CustomsInformation/>
										</Box>
									</Grid>
								</Grid>
							</>
							:
							<>
								<Typography mb={2}>
									Invalid Product ID
								</Typography>
								<BackButton />
							</>
						}
					</Box>
				</SellerDependentView>
			</LoadableContent>
		</Box>
	);

}